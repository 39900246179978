



















import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class InsSearch extends Vue {
  private showSearch: boolean = false;
  private key: string = '';

  get isMobile () {
    return this.$store.state.isMobile;
  }

  searchCms () {
    if (!this.key) {
      return false;
    } else {
      this.key = $.trim(this.key);
      this.$store.dispatch('isShowMenu', false);
      this.$router.push({ path: `/cms/search/${this.key}` });
      this.showSearch = false;
    }
  }

  hideSearch () {
    $(document).click(() => {
      this.showSearch = false;
    });
    $('#search').click((event) => {
      event.stopPropagation();
    });
  }

  mounted () {
    if (!this.isMobile) {
      this.hideSearch();
    }
  }
}
